// ** React Imports
import { Fragment, useMemo } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { Typography } from '@mui/material'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Modules Imports
import { Swiper, SwiperSlide } from 'swiper/react'

// ** Source code Imorts
import { AttachType } from 'src/types/apps/chatTypes'

// ** Styles Imports
import 'swiper/css'

const activeNavStyles = (isActiveMenu: boolean) => ({
  opacity: isActiveMenu ? 1 : 0,
  transitionProperty: 'opacity',
  transitionDuration: '500ms'
})

const arrowsStyles = () => ({
  opacity: 0.6,
  transitionProperty: 'opacity',
  transitionDuration: '200ms',
  '&:hover': {
    opacity: 1
  }
})

interface IProps {
  isActiveMenu: boolean
  onViewerItemOpen: (state: string | null) => void
  openedItemId: string | null
  mediaAttachesList: AttachType[]
  resetTransform?: (state?: number) => void
}

const NawBlock = ({ isActiveMenu, onViewerItemOpen, openedItemId, mediaAttachesList, resetTransform }: IProps) => {
  const { prevItemId, nextItemId } = useMemo(() => {
    const currentIndex = mediaAttachesList.indexOf(mediaAttachesList.find(({ _id }) => _id === openedItemId) as any)

    return {
      prevItemId: mediaAttachesList[currentIndex - 1]?._id,
      nextItemId: mediaAttachesList[currentIndex + 1]?._id
    }
  }, [openedItemId, mediaAttachesList])

  return (
    <Fragment>
      <Box sx={{ ...activeNavStyles(isActiveMenu) }}>
        {prevItemId && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: 1,
              px: 2,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              ...arrowsStyles()
            }}
            onClick={() => onViewerItemOpen(prevItemId)}
          >
            <Icon icon='mdi:arrow-left' />
          </Box>
        )}
        {nextItemId && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              height: 1,
              px: 2,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              ...arrowsStyles()
            }}
            onClick={() => onViewerItemOpen(nextItemId)}
          >
            <Icon icon='mdi:arrow-right' />
          </Box>
        )}
      </Box>
      <IconButton
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          m: 2,
          ...activeNavStyles(isActiveMenu)
        }}
        onClick={() => onViewerItemOpen(null)}
      >
        <Icon icon='mdi:close' />
      </IconButton>
      <Box
        sx={{
          p: 2,
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          background: 'black',
          borderRadius: 2,
          width: 1,
          maxWidth: 480,
          ...activeNavStyles(isActiveMenu)
        }}
      >
        <Swiper
          slidesPerView={3}
          breakpoints={{
            400: { slidesPerView: 4 },
            768: { slidesPerView: 5 }
          }}
        >
          {mediaAttachesList.map(({ _id, attach, name }) => (
            <SwiperSlide key={_id}>
              <Button
                variant='text'
                sx={{
                  flexDirection: 'column',
                  opacity: _id === openedItemId ? 0.5 : 1
                }}
                onClick={() => {
                  if (resetTransform) resetTransform(0)
                  onViewerItemOpen(_id)
                }}
              >
                <Typography
                  variant='caption'
                  sx={{
                    maxWidth: 50,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                  }}
                  title={name}
                >
                  <Box
                    sx={{
                      mx: 'auto',
                      mb: 1,
                      backgroundImage: `url(${attach})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      width: 30,
                      height: 30
                    }}
                  />
                  <span>{name}</span>
                </Typography>
              </Button>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Fragment>
  )
}

export default NawBlock
