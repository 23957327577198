import jws from 'jws'

const secret = 'ULvve7gSeee'

export const formData = {
  save: (key: string, payload: object) => {
    const encodedData = jws.sign({
      header: { alg: 'HS256' },
      payload,
      secret
    })

    localStorage.setItem(`formData.${key}`, encodedData)
  },
  get: (key: string) => {
    try {
      const encodedDataFromStorage = localStorage.getItem(`formData.${key}`)
      if (!encodedDataFromStorage) return null

      const decodedDataFromStorage = jws.decode(encodedDataFromStorage)?.payload
      if (!decodedDataFromStorage) return null

      return JSON.parse(decodedDataFromStorage)
    } catch {
      return null
    }
  },
  clear: (key: string) => localStorage.removeItem(`formData.${key}`),
  clearAll: () => {
    Object.keys(localStorage).forEach(key => {
      if (key.includes('formData.')) localStorage.removeItem(key)
    })
  }
}
