// ** React Imports
import { Fragment } from 'react'

// ** Next Imports
import Link from 'next/link'

// ** MUI Imports
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import { useMediaQuery } from '@mui/material'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Source code imports
import mediaQueries from 'src/@core/styles/libs/mediaqueries'
import { useIntl } from 'react-intl'

// ** Hooks
import { useAuth } from 'src/hooks/useAuth'

const GeneralButton = () => {
  // ** Hooks
  const { formatMessage } = useIntl()
  const tabletSmallMatch = useMediaQuery(mediaQueries.tabletSmall)

  const { user } = useAuth()

  if (user?.accountStatus === 'pending')
    return (
      <Fragment>
        {!tabletSmallMatch ? (
          <Button sx={{ mr: 4 }} variant='contained' component={Link} href='/account-settings/#documents-flow'>
            {formatMessage({ id: 'begin' })}
          </Button>
        ) : (
          <IconButton color='inherit' component={Link} href='/account-settings/#documents-flow'>
            <Icon icon='mdi:mdi-file-document' />
          </IconButton>
        )}
      </Fragment>
    )

  return (
    <Fragment>
      {!tabletSmallMatch ? (
        <Button sx={{ mr: 4 }} variant='contained' component={Link} href='/release/add'>
          {formatMessage({ id: 'upload' })}
        </Button>
      ) : (
        <IconButton color='inherit' component={Link} href='/release/add'>
          <Icon icon='mdi:upload' />
        </IconButton>
      )}
    </Fragment>
  )
}

export default GeneralButton
