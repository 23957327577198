// ** Type import
import { HorizontalNavItemsType } from 'src/@core/layouts/types'

const navigation = ({
  formatMessage,
  draftCount,
  changeRequiredCount,
  moderationCount,
  releasedCount,
  isOpenedFinanceReports
}: any): HorizontalNavItemsType => [
  {
    title: formatMessage({ id: 'home-page' }),
    icon: 'mdi:pages',
    path: '/'
  },
  {
    title: formatMessage({ id: 'new-release' }),
    icon: 'mdi:add',
    path: '/release/add'
  },
  {
    title: formatMessage({ id: 'your-music' }),
    icon: 'mdi:queue-music',
    children: [
      {
        title: formatMessage({ id: 'all-releases' }),
        path: '/releases/released',
        count: releasedCount
      },
      {
        title: formatMessage({ id: 'drafts' }),
        path: '/releases/new',
        count: draftCount
      },
      {
        title: formatMessage({ id: 'moderation' }),
        path: '/releases/moderation',
        count: moderationCount
      },
      {
        title: formatMessage({ id: 'changes-required' }),
        path: '/releases/changes-required',
        count: changeRequiredCount
      }
    ]
  },
  {
    title: formatMessage({ id: 'marketing' }),
    icon: 'mdi:album',
    path: '/releases/promo'
  },
  {
    title: formatMessage({ id: 'analytics' }),
    icon: 'mdi:chart-pie-outline',
    path: '/analytics'
  },
  {
    title: formatMessage({ id: 'news' }),
    icon: 'mdi:newspaper',
    path: '/news'
  },
  ...(isOpenedFinanceReports
    ? [
        {
          title: formatMessage({ id: 'reports' }),
          icon: 'mdi:file-chart',
          path: '/finance-reports'
        }
      ]
    : []),
  {
    title: formatMessage({ id: 'knowledge-base' }),
    icon: 'mdi:school',
    path: '/help-center'
  }
]

export default navigation
