// ** React Imports
import { ReactNode } from 'react'

// ** Next Imports
import Link from 'next/link'

// ** MUI Imports
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

// ** Modules Imports
import { useIntl } from 'react-intl'

const Image = styled('img')({})

interface IProps {
  code?: number
  title: string
  description: string
  renderButton?: ReactNode
}

const ErrorPage = ({ code, title, description, renderButton }: IProps) => {
  const { formatMessage } = useIntl()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: 1,
        height: '100vh',
        minHeight: 900
      }}
    >
      <Box
        sx={{
          maxWidth: 1480,
          maxHeight: 900,
          m: '0 auto',
          width: 1,
          height: 1,
          position: 'relative'
        }}
      >
        <Box
          sx={{
            p: 0,
            m: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: 1,
            height: 1,
            overflow: 'hidden'
          }}
        >
          <Image
            sx={theme => ({
              position: 'absolute',
              width: theme.spacing(260),
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              [theme.breakpoints.down('md')]: {
                width: theme.spacing(200)
              }
            })}
            src='/images/not-found/ellipse.png'
            alt='ellipse'
          />
          <Box
            sx={theme => ({
              position: 'absolute',
              width: theme.spacing(105.75),
              top: '52.5%',
              left: '37.5%',
              transform: 'translate(-100%, -100%)',
              [theme.breakpoints.down('md')]: {
                top: '38.5%',
                left: '42.5%',
                transform: 'translate(-80%, -100%)'
              }
            })}
          >
            <Image
              sx={{
                width: '100%',
                height: 'auto',
                animation: 'headphonesAnim 2.5s linear infinite',
                '@keyframes headphonesAnim': {
                  '0%': {
                    transform: 'translateY(0px)'
                  },
                  '50%': {
                    transform: 'translateY(20px)'
                  },
                  '100%': {
                    transform: 'translateY(0px)'
                  }
                }
              }}
              src='/images/not-found/headphone-dynamic-gradient.png'
              alt='ellipse'
            />
          </Box>
          <Box
            sx={theme => ({
              position: 'absolute',
              height: 'auto',
              top: '37.5%',
              right: '40%',
              transform: 'translate(100%, -100%)',
              [theme.breakpoints.down('md')]: {
                top: '35.5%',
                right: '40%'
              }
            })}
          >
            <Image
              sx={theme => ({
                width: theme.spacing(77),
                [theme.breakpoints.down('md')]: {
                  width: theme.spacing(50)
                },
                animation: 'notesAnim 4.5s linear infinite',
                '@keyframes notesAnim': {
                  '0%': {
                    transform: 'translateY(0px)'
                  },
                  '50%': {
                    transform: 'translateY(30px)'
                  },
                  '100%': {
                    transform: 'translateY(0px)'
                  }
                }
              })}
              src='/images/not-found/music-dynamic-gradient.png'
              alt='music'
            />
          </Box>
          <Box
            sx={theme => ({
              position: 'absolute',
              height: 'auto',
              top: '78.5%',
              left: '45%',
              transform: 'translate(-50%, 0)',
              [theme.breakpoints.down('md')]: {
                top: '78%',
                left: '27.5%',
                transform: 'translate(-50%, 0)'
              }
            })}
          >
            <Image
              sx={theme => ({
                width: theme.spacing(33),
                [theme.breakpoints.down('md')]: {
                  width: theme.spacing(23)
                },
                animation: 'pointerAnim 3.5s linear infinite',
                '@keyframes pointerAnim': {
                  '0%': {
                    transform: 'translate(0px)'
                  },
                  '50%': {
                    transform: 'translate(30px, -30px)'
                  },
                  '100%': {
                    transform: 'translate(0px)'
                  }
                }
              })}
              src='/images/not-found/location-dynamic-gradient.png'
              alt='location'
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -45%)',
            width: 1
          }}
        >
          <Box sx={{ width: 1, maxWidth: 420, p: 1 }}>
            {code && (
              <Typography
                sx={theme => ({
                  fontSize: theme.spacing(32),
                  fontWeight: 500,
                  color: theme.palette.common.white,
                  lineHeight: 1,
                  mb: 2
                })}
              >
                {code}
              </Typography>
            )}
            <Typography
              sx={theme => ({
                fontSize: theme.spacing(6),
                fontWeight: 500,
                color: theme.palette.common.white,
                mb: 4
              })}
            >
              {title}
            </Typography>
            <Typography
              variant='body2'
              sx={theme => ({
                mb: 20,
                color: theme.palette.common.white
              })}
            >
              {description}
            </Typography>
          </Box>
          {!!renderButton ? (
            renderButton
          ) : (
            <Button
              href='/'
              component={Link}
              variant='contained'
              sx={theme => ({
                p: theme.spacing(4, 12),
                borderRadius: 0
              })}
            >
              <Typography
                sx={theme => ({
                  fontSize: theme.spacing(4.25),
                  fontWeight: 500,
                  color: theme.palette.common.white
                })}
              >
                {formatMessage({ id: 'back-to-home' })}
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ErrorPage
