// ** React Imports
import { useRef, useEffect, useState, useMemo } from 'react'
import dayjs from 'dayjs'

// ** MUI Imports
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Components

// ** Custom Components Imports
import CustomAvatar from 'src/@core/components/mui/avatar'
import Viewer from 'src/@core/components/viewer'

// ** Types Imports
import {
  ChatLogType,
  MessageType,
  ChatLogChatType,
  MessageGroupType,
  FormattedChatsType
} from 'src/types/apps/chatTypes'

// ** Hooks Imports
import { useAuth } from 'src/hooks/useAuth'
import { getFullUserName, getUserAvatar } from 'src/@core/utils/user'
import { isToday } from 'src/@core/utils/format'

// ** Source code Imports
import MsgAttaches from './MsgAttaches'

const ChatLog = ({ messages, onNextPage, isDisabledPagination }: ChatLogType) => {
  // ** Hooks
  const theme = useTheme()

  // ** Auth
  const { user } = useAuth()

  // ** Formats chat data based on sender
  const data = useMemo(() => {
    if (!user) return []

    let chatLog: MessageType[] | [] = []
    if (messages) {
      chatLog = messages
    }

    const formattedChatLog: FormattedChatsType[] = []
    let chatMessageSenderId = user?._id

    let msgGroup: MessageGroupType = {
      user,
      senderId: chatMessageSenderId,
      messages: []
    }
    chatLog.forEach((msg: MessageType, index: number) => {
      if (chatMessageSenderId === msg.user._id) {
        msgGroup.messages.push({
          time: msg.createdAt,
          msg: msg.message,
          attaches: msg.attaches,
          feedback: { isSent: true, isDelivered: true, isSeen: true }
        })
      } else {
        chatMessageSenderId = msg.user._id
        formattedChatLog.push(msgGroup)
        msgGroup = {
          user: msg.user,
          senderId: msg.user._id,
          messages: [
            {
              time: msg.createdAt,
              msg: msg.message,
              attaches: msg.attaches,
              feedback: { isSent: true, isDelivered: true, isSeen: true }
            }
          ]
        }
      }

      if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
    })

    return formattedChatLog
  }, [messages])

  const allMediaAttaches = useMemo(() => {
    let attaches: any[] = []

    data.forEach(item => {
      item.messages.forEach(message => {
        attaches = [...attaches, ...message.attaches.filter(({ type }) => type === 'image')]
      })
    })

    return attaches
  }, [data])

  // ** State
  const [scrolled, setScrolled] = useState(false)
  const [beforeMesages, setBeforeMessages] = useState(messages)
  const [scrollPos, setScrollPos] = useState(-1)
  const [isScrollEnd, setScrollEnd] = useState(false)
  const [viewerItemOpenedId, setViewerItemOpen] = useState<string | null>(null)

  // ** Ref
  const virtualListRef = useRef<any>()

  useEffect(() => {
    if (scrollPos !== -1) {
      virtualListRef.current.scrollTo(0, virtualListRef.current.scrollHeight - scrollPos)

      setScrollPos(-1)
    }
  }, [messages.length])

  useEffect(() => {
    const scrollPosition = virtualListRef.current.scrollTop + virtualListRef.current.clientHeight + 600

    const scrollAllowed = beforeMesages !== messages
    if (scrollAllowed) setBeforeMessages(messages)

    if (scrollPosition >= virtualListRef.current.scrollHeight && scrollAllowed) {
      virtualListRef.current.scrollTo(0, virtualListRef.current.scrollHeight)
    } else if (virtualListRef.current.scrollHeight > virtualListRef.current.clientHeight && !scrolled) {
      virtualListRef.current.scrollTo(0, virtualListRef.current.scrollHeight)

      setScrolled(true)
    }
  }, [messages, scrolled, virtualListRef])

  const handlePrependItems = () => {
    const scrollPosition = virtualListRef.current.scrollTop + virtualListRef.current.clientHeight

    setScrollEnd(scrollPosition + 600 > virtualListRef.current.scrollHeight)

    if (virtualListRef.current.scrollTop < 50) {
      if (!isDisabledPagination) onNextPage()

      setScrollPos(virtualListRef.current.scrollHeight)
    }
  }

  // ** Renders row messages
  const renderRow = (item: FormattedChatsType, index: number) => {
    const isSender = item?.user?._id === user?._id

    return (
      <Box
        key={index}
        sx={{
          display: 'flex',
          p: 5,
          flexDirection: !isSender ? 'row' : 'row-reverse',
          mb: index !== data.length - 1 ? 4 : undefined
        }}
      >
        <div>
          <CustomAvatar
            skin='light'
            color='info'
            sx={{
              width: '2rem',
              height: '2rem',
              fontSize: '0.875rem',
              ml: isSender ? 3.5 : undefined,
              mr: !isSender ? 3.5 : undefined
            }}
            src={getUserAvatar(item.user, { theme })}
            alt={getFullUserName(user)}
          />
        </div>

        <Box className='chat-body' sx={{ width: '100%' }}>
          {item.messages.map((chat: ChatLogChatType, index: number, { length }: { length: number }) => {
            const time = new Date(chat.time)

            return (
              <Box key={index} sx={{ '&:not(:last-of-type)': { mb: 3.5 } }}>
                <div>
                  <Typography
                    sx={{
                      boxShadow: 1,
                      borderRadius: 1,
                      width: 'fit-content',
                      fontSize: '0.875rem',
                      p: theme => theme.spacing(3, 4),
                      ml: isSender ? 'auto' : undefined,
                      borderTopLeftRadius: !isSender ? 0 : undefined,
                      borderTopRightRadius: isSender ? 0 : undefined,
                      color: isSender ? 'common.white' : 'text.primary',
                      backgroundColor: isSender ? 'primary.main' : 'background.paper',
                      wordBreak: 'break-word',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    <span>{chat.msg}</span>
                    <MsgAttaches attaches={chat.attaches} onViewerItemOpen={setViewerItemOpen} />
                  </Typography>
                </div>
                {index + 1 === length ? (
                  <Box
                    sx={{
                      mt: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: isSender ? 'flex-end' : 'flex-start'
                    }}
                  >
                    <Typography variant='caption'>
                      {time && isToday(time)
                        ? new Date(time).toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: false
                          })
                        : dayjs(new Date(time)).format('DD.MM.YYYY HH:mm')}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            )
          })}
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: 1,
          height: `calc(100vh - 300px)`
        }}
      >
        <Box sx={{ py: 5, width: 1, height: 1, overflow: 'auto' }} ref={virtualListRef} onScroll={handlePrependItems}>
          {data.map(renderRow)}
        </Box>
        <IconButton
          color='primary'
          sx={theme => ({
            position: 'absolute',
            right: theme.spacing(3),
            bottom: theme.spacing(3),
            zIndex: 2,
            opacity: isScrollEnd ? 0 : 1,
            transform: `scale(${isScrollEnd ? 0.5 : 1})`,
            transitionProperty: 'opacity, transform',
            transitionDuration: '200ms'
          })}
          onClick={() =>
            virtualListRef.current.scrollTo({
              top: virtualListRef.current.scrollHeight,
              behavior: 'smooth'
            })
          }
        >
          <Icon icon='mdi:arrow-down' />
        </IconButton>
      </Box>
      <Viewer
        openedItemId={viewerItemOpenedId}
        open={!!viewerItemOpenedId}
        onViewerItemOpen={setViewerItemOpen}
        mediaAttachesList={allMediaAttaches}
      />
    </>
  )
}

export default ChatLog
