import { IConfig } from '@unleash/proxy-client-react'

const config: IConfig = {
  url: `${process.env.NEXT_PUBLIC_FEATURE_FLAG_API_URL}/${process.env.NEXT_PUBLIC_FEATURE_FLAG_API_ENV}`,
  clientKey: process.env.NEXT_PUBLIC_FEATURE_FLAG_API_KEY || '',
  refreshInterval: 15,
  appName: 'silk'
}

export default config
