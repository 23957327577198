// ** React Imports
import { useState, SyntheticEvent, Fragment, ReactNode, useEffect } from 'react'

// ** Next Imports
import Link from 'next/link'

// ** MUI Imports
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled, Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiMenu, { MenuProps } from '@mui/material/Menu'
import MuiMenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import Typography, { TypographyProps } from '@mui/material/Typography'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Components
import PerfectScrollbarComponent from 'react-perfect-scrollbar'

// ** Type Imports
import { ThemeColor } from 'src/@core/layouts/types'
import { Settings } from 'src/@core/context/settingsContext'
import { CustomAvatarProps } from 'src/@core/components/mui/avatar/types'

// ** Custom Components Imports
import CustomChip from 'src/@core/components/mui/chip'
import CustomAvatar from 'src/@core/components/mui/avatar'

// ** Source code Imports
import notificationsStore, {
  getNotificationsList,
  readAllNotice,
  setUnreadedCount
} from 'src/state-manager/notifications'
import { useStore } from 'effector-react'
import { CircularProgress } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useTimeFormat } from 'src/@core/hooks'

export type NotificationsType = {
  createdAt: string
  data: {
    icon: string
    title: string
    text: string
    link: string
    typeLink: string
  }
} & (
  | { avatarAlt: string; avatarImg: string; avatarText?: never; avatarColor?: never; avatarIcon?: never }
  | {
      avatarAlt?: never
      avatarImg?: never
      avatarText: string
      avatarIcon?: never
      avatarColor?: ThemeColor
    }
  | {
      avatarAlt?: never
      avatarImg?: never
      avatarText?: never
      avatarIcon: ReactNode
      avatarColor?: ThemeColor
    }
)
interface Props {
  settings: Settings
}

// ** Styled Menu component
const Menu = styled(MuiMenu)<MenuProps>(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 380,
    overflow: 'hidden',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}))

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)<MenuItemProps>(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}))

// ** Styled PerfectScrollbar component
const PerfectScrollbar = styled(PerfectScrollbarComponent)({
  maxHeight: 349
})

// ** Styled Avatar component
const Avatar = styled(CustomAvatar)<CustomAvatarProps>({
  width: 38,
  height: 38,
  fontSize: '1.125rem'
})

// ** Styled component for the title in MenuItems
const MenuItemTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  flex: '1 1 100%',
  overflow: 'hidden',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginBottom: theme.spacing(0.75)
}))

// ** Styled component for the subtitle in MenuItems
const MenuItemSubtitle = styled(Typography)<TypographyProps>({
  flex: '1 1 100%',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap'
})

const ScrollWrapper = ({ children, hidden }: { children: ReactNode; hidden: boolean }) => {
  if (hidden) {
    return <Box sx={{ maxHeight: 349, overflowY: 'auto', overflowX: 'hidden' }}>{children}</Box>
  } else {
    return <PerfectScrollbar options={{ wheelPropagation: false, suppressScrollX: true }}>{children}</PerfectScrollbar>
  }
}

const NotificationDropdown = (props: Props) => {
  const fullFormat = 'YYYY-MM-DD HH:mm'
  const dateFormat = 'YYYY-MM-DD'
  const timeFormat = 'HH:mm'

  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)

  // ** Hook
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  const { formatDate } = useTimeFormat()

  const nowDate = formatDate(new Date(), dateFormat)

  const formatedDate = (date: any) => {
    const curDate = formatDate(date, dateFormat)

    if (nowDate === curDate) {
      return Date.parse(date) ? formatDate(date, timeFormat) : ''
    }

    return Date.parse(date) ? formatDate(date, fullFormat) : ''
  }

  // ** Stores
  const { data, unreaded, loading } = useStore(notificationsStore.notifications)

  // ** Fetch notifications
  useEffect(() => {
    if (Boolean(anchorEl)) {
      getNotificationsList()
    }
  }, [anchorEl])

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = async () => {
    readAllNotice()
    setUnreadedCount(0)
    setAnchorEl(null)
  }

  const RenderAvatar = ({ notification }: { notification: NotificationsType }) => {
    return (
      <Avatar skin='light'>
        <Icon icon={notification.data.icon} />
      </Avatar>
    )
  }

  return (
    <Fragment>
      <IconButton color='inherit' aria-haspopup='true' onClick={handleDropdownOpen} aria-controls='customized-menu'>
        <Badge
          color='error'
          variant='dot'
          invisible={!unreaded}
          sx={{
            '& .MuiBadge-badge': { top: 4, right: 4, boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}` }
          }}
        >
          <Icon icon='mdi:bell-outline' />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{ cursor: 'default', userSelect: 'auto', backgroundColor: 'transparent !important' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography sx={{ cursor: 'text', fontWeight: 600 }}>
              <FormattedMessage id='notifications' />
            </Typography>
            {unreaded > 0 && (
              <CustomChip
                skin='light'
                size='small'
                color='primary'
                label={<FormattedMessage id='notifications-new' values={{ count: unreaded }} />}
                sx={{ height: 20, fontSize: '0.75rem', fontWeight: 500, borderRadius: '10px' }}
              />
            )}
          </Box>
        </MenuItem>
        <ScrollWrapper hidden={hidden}>
          {loading ? (
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {data.map((notification: NotificationsType, index: number) => {
                const { title, text, link, typeLink } = notification.data
                const LinkComponent = typeLink === 'app' ? Link : 'a'

                return (
                  <Fragment key={index}>
                    {!!index && <Divider />}
                    <Box
                      sx={{
                        py: 4,
                        px: 3,
                        pr: 6
                      }}
                    >
                      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <RenderAvatar notification={notification} />
                        <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                          <MenuItemTitle>{title}</MenuItemTitle>
                          <MenuItemSubtitle variant='body2'>{text}</MenuItemSubtitle>
                          {link && (
                            <Button
                              size='medium'
                              sx={{ mt: 2 }}
                              component={LinkComponent}
                              href={link}
                              onClick={handleDropdownClose}
                            >
                              Перейти
                            </Button>
                          )}
                        </Box>
                        <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                          {formatedDate(notification.createdAt)}
                        </Typography>
                      </Box>
                    </Box>
                  </Fragment>
                )
              })}
            </>
          )}
        </ScrollWrapper>
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            py: 3.5,
            borderBottom: 0,
            cursor: 'default',
            userSelect: 'auto',
            backgroundColor: 'transparent !important',
            borderTop: theme => `1px solid ${theme.palette.divider}`
          }}
        >
          <Button fullWidth variant='contained' onClick={handleDropdownClose}>
            <FormattedMessage id='read-all-notifications' />
          </Button>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default NotificationDropdown
