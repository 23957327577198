// ** React Imports
import { useState, useEffect } from 'react'

// ** MUI Imports
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Modules Imports
import { useStore } from 'effector-react'
import { useIntl } from 'react-intl'
import { useFlag } from '@unleash/proxy-client-react'

// ** Source code Imports
import globalStore, { getTechBreakNotificationData } from 'src/state-manager/global'

interface NotificationComponentProps {
  startAt: string
  endAt: string
}

const NotificationComponent = ({ startAt, endAt }: NotificationComponentProps) => {
  const { formatMessage } = useIntl()

  const [open, setOpen] = useState(true)

  if (!open) return null

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 1,
        p: 2,
        backgroundColor: 'error.main',
        zIndex: 99999
      }}
    >
      <Typography variant='subtitle1' sx={{ color: 'text.primary' }}>
        {formatMessage({ id: 'tech-break-notice' }, { startAt, endAt })}
      </Typography>
      <IconButton onClick={() => setOpen(state => !state)}>
        <Icon icon='mdi:close' />
      </IconButton>
    </Box>
  )
}

const TechBreakNotification = () => {
  const { startAt, endAt, loaded } = useStore(globalStore.techBreakNotificationData)

  const noticeAboutClosedForTechnicalBreak = useFlag('notice_about_closed_for_technical_break')

  useEffect(() => {
    getTechBreakNotificationData()
  }, [])

  if (!noticeAboutClosedForTechnicalBreak || !loaded) return null

  return <NotificationComponent startAt={startAt} endAt={endAt} />
}

export default TechBreakNotification
